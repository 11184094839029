import Vue from 'vue/dist/vue.esm'
import VueAxios from 'vue-axios'
import VueRouter from 'vue-router'
import Vuex from 'vuex'

// components
import { Datetimepicker } from 'buefy'
import VTooltip from 'v-tooltip'
import VueToast from 'vue-toast-notification';

// Helpers & Plugins
import axios from 'helpers/axios'
import { i18n } from 'plugins/i18n'
import 'plugins/vee-validate'

// Telemetry
import installSentry from "plugins/sentry";

require("@rails/ujs").start()

import '../stylesheets/buefy.scss'
import '../stylesheets/application.scss'
import 'vue-toast-notification/dist/theme-sugar.css'

// custom components
import HeroBlank from 'components/shared/HeroBlank'

if (process.env.NODE_ENV === "production") installSentry()

Vue.use(Datetimepicker)
Vue.use(VueAxios, axios)
Vue.use(VueRouter)
Vue.use(VTooltip)
Vue.use(VueToast)
Vue.use(Vuex)

Vue.component('flash-message', () => import('components/FlashMessage'))

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementsByClassName('site-page').length > 0) {
    const siteVm = new Vue({
      el: '.site-page',
      i18n,
      components: {
        'AboutUsCriteria': () => import('components/aboutUs/Criteria'),
        'AboutUsPrinciples': () => import('components/aboutUs/Principles'),
        'AboutUsTimeline': () => import('components/aboutUs/Timeline'),
        'ArticleBook': () => import('components/articles/Book'),
        'ArticleList': () => import('components/articles/List'),
        'AuthorContentList': () => import('components/authors/ContentList'),
        'ContentCardDownload': () => import('components/contents/CardDownload'),
        'ContentHero': () => import('components/contents/Hero'),
        'ContentList': () => import('components/contents/List'),
        'ContentLeftbarDatasheet': () => import('components/contents/LeftbarDatasheet'),
        'CourseCategorization': () => import('components/tools/courses/CourseCategorization'),
        'EptStatisticsStateSelector': () => import('components/indicators/eptStatistics/StateSelector'),
        'EptStatisticsIndicatorItem': () => import('components/indicators/eptStatistics/IndicatorItem'),
        'FavoriteList': () => import('components/favorites/List'),
        'Faq': () => import('components/faq/Faq'),
        'FeaturedList': () => import('components/home/FeaturedList'),
        'GdprCompliance': () => import('components/GdprCompliance'),
        'HeaderAcessibility': () => import('components/header/Acessibility'),
        'HeaderFieldSearch': () => import('components/header/FieldSearch'),
        'HeaderMenu': () => import('components/header/Menu'),
        'HeaderStateMenu': () => import('components/header/StateMenu'),
        'HomeContentList': () => import('components/home/ContentList'),
        'HomeHero': () => import('components/home/Hero'),
        'HomeHero': () => ({
          component: import('components/home/Hero'),
          loading: HeroBlank,
          error: HeroBlank,
          delay: 0
        }),
        'LegislationList': () => import('components/ept/legislationList'),
        'MiniHeader': () => import('components/shared/MiniHeader'),
        'MobileMenu': () => import('components/header/MobileMenu'),
        'MobileStateMenu': () => import('components/header/MobileStateMenu'),
        'NetworkCapacityFormNew': () => import('components/tools/networkCapacityInstalleds/FormNew.vue'),
        'NetworkDiagnosticsIndex': () => import('components/tools/networkDiagnostics/index'),
        'NetworkDiagnosticsNew': () => import('components/tools/networkDiagnostics/new'),
        'NoticesList': () => import('components/notices/List'),
        'RecentList': () => import('components/recents/List'),
        'ReferenceCurriculum': () => import('components/referenceCurriculum/index'),
        'RelatedList': () => import('components/shared/RelatedList'),
        'SearchResults': () => import('components/search/Results'),
        'ShowTag': () => import('components/tags/Show'),
        'SocialShareContent': () => import('components/social/ShareContent'),
        'SocialLateralShareOptions': () => import('components/social/LateralShareOptions'),
        'StateOverviewBlock': () => import('components/state/overview/Block'),
        'StateOverviewState': () => import('components/state/overview/State'),
        'ToolGeolocationSchool': () => import('components/tools/geolocationSchool'),
        'ToolReferenceCurriculum': () => import('components/tools/referenceCurriculum'),
        'TutorialButton': () => import('components/tutorial/Button'),
        'TutorialList': () => import('components/tutorial/List'),
        'ToolTutorial': () => import('components/tutorials/tool_tutorial'),
        'UsefulLinkList': () => import('components/usefulLinks/List'),
        'UserDestroyAccount': () => import('components/users/UserDestroyAccount'),
        'UserLocation': () => import('components/users/UserLocation'),
        'UserMenu': () => import('components/header/UserMenu'),

        //Area Restrita
        'StatesArea': () => import('components/statesArea/index'),

        //Area Restrita Forum
        'StatesForumIndex': () => import('components/statesArea/forum/index.vue'),
        'StatesForumShow': () => import('components/statesArea/forum/show.vue'),
        'StatesForumNew': () => import('components/statesArea/forum/new.vue'),
        'StatesForumEdit': () => import('components/statesArea/forum/edit.vue'),

        'StatesCalendar': () => import('components/statesArea/calendar/index'),
        'HeroStatesArea': () => import('components/statesArea/components/HeroStatesArea'),
        'HeroSingle': () => import('components/statesArea/components/HeroSingle'),
        'StatesNews': () => import('components/statesArea/news/index'),

        //Area Restrita - Arquivos
        'FileManagerIndex': () => import('components/statesArea/fileManagers/index.vue'),
        'FileManagerNew': () => import('components/statesArea/fileManagers/new.vue'),
        'FileManagerEdit': () => import('components/statesArea/fileManagers/edit.vue'),

        // Ferramentas
        'PlanningTeachers': () => import('components/statesArea/tools/planningTeachers/Home.vue'),
        'PlanningOffers': () => import('components/statesArea/tools/planningOffers/Home.vue'),

        // Peept
        'PeeptHome': () => import('components/statesArea/tools/peept/Home'),
        'PeeptQuestion': () => import('components/statesArea/tools/peept/Question'),
        'PeeptResults': () => import('components/statesArea/tools/peept/Results'),
        'PeeptShareResults': () => import('components/statesArea/tools/peept/ShareResults'),
        'PeeptAnalysisPdfReport': () => import('components/statesArea/tools/peept/PdfReport'),

        // Diagnóstico das redes
        'NetworkDiagnosticsHome': () => import('components/statesArea/tools/network/Home'),
        'NetworkDiagnosticsNew': () => import('components/statesArea/tools/network/New'),
        'NetworkDiagnosticsDiagnostic': () => import('components/statesArea/tools/network/Diagnostic'),
        'NetworkDiagnosticsReport': () => import('components/statesArea/tools/network/Report'),
        'NetworkDiagnosticsPdfReport': () => import('components/statesArea/tools/network/PDFReport'),
        'NetworkDiagnosticsFiles': () => import('components/statesArea/tools/network/Files'),
        'NetworkDiagnosticPdfBrazilReport': () => import('components/admin/networkDiagnostics/PdfBrazilReport.vue'),

        // instrumento de prontidão
        'ToolReadinessInstrument': () => import('components/tools/readinessInstrument'),
        'ReadinessInstrument': () => import('components/readinessInstrument/index'),
        'ReadinessInstrumentNew': () => import('components/readinessInstrument/new'),
        'ReadinessInstrumentResults': () => import('components/readinessInstrument/results'),
        'ReadinessInstrumentResultsPdf': () => import('components/readinessInstrument/results_pdf'),
        'ReadinessInstrumentShareResults': () => import('components/readinessInstrument/share_results'),
        'ReadinessQuestIntro': () => import('components/readinessInstrument/questIntro'),
        'StudentsListeningHome': () => import('components/StudentsListening/Home.vue'),
        'StudentsListeningResponse': () => import('components/StudentsListening/Response.vue'),
      }
    })
  }

  if (document.getElementsByClassName('admin').length > 0) {
    const admin = new Vue({
      el: '.admin',
      i18n,
      components: {
        'AdminAdminList': () => import('components/admin/admin/List'),
        'AdminArticleForm': () => import('components/admin/articles/Form'),
        'AdminArticleDraftList': () => import('components/admin/articles/DraftList'),
        'AdminAuthorForm': () => import('components/admin/authors/Form'),
        'AdminAuthorList': () => import('components/admin/authors/List'),
        'AdminContentList': () => import('components/admin/contents/List'),
        'AdminFaqGroupList': () => import('components/admin/faqGroup/List'),
        'AdminHomeFeature': () => import('components/admin/homeFeatures/index'),
        'AdminIndicatorForm': () => import('components/admin/indicators/Form'),
        'AdminNavbar': () => import('components/admin/shared/AdminNavbar'),
        'AdminNoticeForm': () => import('components/admin/notices/Form'),
        'AdminNoticeList': () => import('components/admin/notices/List'),
        'AdminStateBestPracticeForm': () => import('components/admin/stateBestPractices/Form'),
        'AdminStateBestPracticeList': () => import('components/admin/stateBestPractices/List'),
        'AdminTagForm': () => import('components/admin/tags/Form'),
        'AdminTagList': () => import('components/admin/tags/List'),
        'AdminToolForm': () => import('components/admin/tools/Form'),
        'AdminUsefulLinkForm': () => import('components/admin/usefulLinks/Form'),
        'AdminUsefulLinkList': () => import('components/admin/usefulLinks/List'),
        'AdminUserMetricTiles': () => import('components/admin/users/MetricTiles'),
        'UserMenu': () => import('components/header/UserMenu'),
        'AdminFileManagerList': () => import('components/admin/fileManagers/List'),
        'AdminNetworkDiagnostics': () => import('components/admin/networkDiagnostics/index.vue'),
        'AdminPortalUpdates': () => import('components/admin/home/AdminPortalUpdates'),

        //Relatórios de conteúdos
        'AdminReportsContentsIndex': () => import('components/admin/reports/contents/Index.vue'),

        //Dados de monitoramento - Geral
        'AdminReportsMonitoringDataShow': () => import('components/admin/reports/monitoring_data/Show.vue'),
        
        //Dados de monitoramento - Área dos Estados
        'AdminReportsRestrictMonitoringDataShow': () => import('components/admin/reports/restrict_monitoring_data/Show.vue'),
        'AdminReportsRestrictMonitoringDataShowState': () => import('components/admin/reports/restrict_monitoring_data/ShowState.vue'),

        // admin escuta dos estudantes
        'AdminStudentsListeningHomeIndex': () => import('components/admin/studentsListening/Home/index.vue'),
        'AdminStudentsListeningModelsIndex': () => import('components/admin/studentsListening/Models/index.vue'),

        // planejamento
        'AdminPlanningTeachersIndex': () => import('components/admin/planning/teachers/index.vue'),
        'AdminPlanningTeachersNew': () => import('components/admin/planning/teachers/new.vue'),
        'AdminPlanningTeachersEdit': () => import('components/admin/planning/teachers/edit.vue'),
        'AdminPlanningOffersIndex': () => import('components/admin/planning/offers/index.vue'),
        'AdminPlanningOffersNew': () => import('components/admin/planning/offers/new.vue'),
        'AdminPlanningOffersEdit': () => import('components/admin/planning/offers/edit.vue'),

        // forum admin portal
        'AdminPortalForumIndex': () => import('components/admin/forum/posts/index.vue'),
        'AdminPortalForumNew': () => import('components/admin/forum/posts/new.vue'),
        'AdminPortalForumEdit': () => import('components/admin/forum/posts/edit.vue'),
        'AdminPortalForumShow': () => import('components/admin/forum/posts/show.vue'),

        // arquivos admin portal
        'AdminPortalFilesManagerIndex': () => import('components/admin/files/manager/index.vue'),
        'AdminPortalFilesManagerNew': () => import('components/admin/files/manager/new.vue'),
        'AdminPortalFilesManagerEdit': () => import('components/admin/files/manager/edit.vue'),

        //Botão em crud do portal
        'AdminContentExport': () => import('components/crud/admin/content_export.vue'),

        // admin área restrita
        'AdminRestrictNavbar': () => import('components/adminRestrictArea/shared/AdminRestrictNavbar'),
        'UserPendingList': () => import('components/adminRestrictArea/userPending/List.vue'),
        'RestrictStudentsListening': () => import('components/adminRestrictArea/studentsListening/Home.vue'),
        'RestrictStudentsListeningModel': () => import('components/adminRestrictArea/studentsListening/Model.vue'),
        'RestrictOfferPlanningHome': () => import('components/adminRestrictArea/offerPlanning/Home.vue'),
        'RestrictOfferPlanningHistory': () => import('components/adminRestrictArea/offerPlanning/History.vue'),
        'RestrictTeachersMappingHome': () => import('components/adminRestrictArea/teachersMapping/Home.vue'),
        'RestrictTeachersMappingHistory': () => import('components/adminRestrictArea/teachersMapping/History.vue'),
        'RestrictStudentsListeningResults': () => import('components/adminRestrictArea/studentsListening/Results.vue'),

        // admin instrumento de prontidao
        'AdminReadinessInstrument': () => import('components/admin/readinessInstrument/Index.vue'),
        'AdminStatesPanorama': () => import('components/admin/statesPanorama/index.vue'),
        'AdminStatesPanoramaHistoricSeries': () => import('components/admin/statesPanorama/historicSerie.vue'),
        'AdminStatesPanoramaHistoricUpdates': () => import('components/admin/statesPanorama/historicUpdate.vue'),

        // Admin - Usuários 
        'UsersDashboardIndex': () => import('components/admin/usersDashboard/Index.vue'),

        //Peept
        'AdminPeeptMonitoringIndex': () => import('components/admin/peeptMonitoring/index.vue'),
      }
    })
  }
})
